<template>
  <div id="modal-confirmar" class="modal fade show" style="display: block;">
    <div class="modal-dialog">
         <div class="modal-content">
         <div class="header-modal-cora">
             <h5 class="modal-title titulo-modal-cora" id="exampleModalLabel">Confirme seus dados</h5>
             <small class="subtitulo-modal-cora">*Preencha todos os campos vazios e atualize os existentes</small>
         </div>
         <div class="modal-body">
            <div  class="container-modal">
                 <div class="form-group">
                     <label for="InputName" class="labelCora">Nome</label>
                     <input type="text" class="form-cora-input" v-model="pessoa.pessoa.nome" placeholder="Digite seu nome">
                     <small class="text-danger" v-if="textoNome">{{ this.textoNomeDescricao }}</small>
                 </div>
                 <div class="form-group">
                     <label for="InputDateNascimento" class="labelCora">Data de nascimento</label>
                     <input type="date" class="form-cora-input" v-model="pessoa.pessoa.nascimento" placeholder="Data Nascimento">
                     <small class="text-danger" v-if="textoNascimento">Insira uma data de nascimento.</small>
                 </div>
                 <div class="form-group">
                     <label for="InputGenero" class="labelCora">Gênero</label>
                    <SelectPersonalizado :options="pessoa.genero" :pessoa="pessoa.pessoa" :tipo="'o gênero'" @seleciona="seleciona" />
                     <small class="text-danger" v-if="textoGenero">Insira um gênero.</small>
                 </div>
                 <div class="form-group">
                     <label for="InputTelefone" class="labelCora" >Telefone</label>
                     <input type="text" v-mask="'(##) #####-####'" v-model="pessoa.pessoa.telefone" class="form-cora-input" placeholder="Opcional">
                     <small class="text-danger">Informação Opcional</small>
                 </div>
                 <div class="form-group">
                     <label for="InputGenero" class="labelCora">Estado Civil</label>
                     <SelectPersonalizado :options="pessoa.estado_civil" :pessoa="pessoa.pessoa" :tipo="'o estado civil'" @seleciona="seleciona" />
                     <small class="text-danger" v-if="textoEstadoCivil">Insira um estado civil válido.</small>
                 </div>
                 <div class="form-group">
                     <label for="InputGenero" class="labelCora">Escolaridade</label>
                     <SelectPersonalizado :options="pessoa.escolaridade" :pessoa="pessoa.pessoa" :tipo="'a escolaridade'" @seleciona="seleciona" />
                     <small class="text-danger" v-if="textoEscolaridade">Insira uma escolaridade válida.</small>
                 </div>
                 <div class="form-group">
                     <label for="InputNumeroFilhos" class="labelCora">Número de filhos</label>
                     <input type="number" class="form-cora-input" placeholder="Opcional" v-model="pessoa.pessoa.numero_filhos">
                     <small class="text-danger">Informação Opcional</small>
                 </div>
                 <div class="form-group">
                     <label for="inputRendaBruta" class="labelCora">Renda Bruta</label>
                     <CurrencyInput
                         v-model="rendaBruta"
                         :options="currencyOptions"
                         class="form-cora-input"
                         placeholder="Opcional"
                     />
                     <small class="text-danger">Informação Opcional</small>
                 </div>
            </div>
         </div>
         <div class="modal-footer justify-content-center">
             <button type="button" @click="concluirSalvar()" :class="{'botao-modal-cora':btn.length == 0, 'botao-modal-cora-desativado':btn.length > 0}" :disabled="btn.length != 0">Concluir</button>
         </div>
         </div>
     </div>
 </div>
</template>

<script>

import {
 BRow,
 BCol,
 BFormGroup,
 BContainer,
 BFormInput
} from 'bootstrap-vue';
import CurrencyInput from '@core/components/currency-input/CurrencyInput.vue'
import SelectPersonalizado from "@/views/pages/cora/Cora/SelectPersonalizado.vue";

export default {
components:{
  BRow,
  BCol,
  BFormGroup,
  BContainer,
  BFormInput,
  CurrencyInput,
  SelectPersonalizado
},

props: {
 pessoa: {
   default: false,
   required: true,
 }
},

data(){
 return{
   btn: [],
   nome: null,
   textoNome: false,
   textoNomeDescricao: '',
   textoEscolaridade: false,
   textoNascimento: false,
   textoEstadoCivil: false,
   textoGenero:false,
   telefone: null,
   rendaBruta: null,
 }
},

computed: {
 currencyOptions(){
     return {
         currency: 'BRL',
         currencyDisplay: 'symbol',
         hideCurrencySymbolOnFocus: false,
         hideGroupingSeparatorOnFocus: false,
         hideNegligibleDecimalDigitsOnFocus: false,
         autoDecimalDigits: true,
         useGrouping: true,
     }
 }
},

methods:{
 concluirSalvar(){
   this.pessoa.pessoa.renda_bruta = this.rendaBruta;
   this.$emit('concluirSalvar', this.pessoa);
 },

 removerValidacao(valor){
   let index = this.btn.indexOf(valor);
   if (index !== -1) {
     this.btn.splice(index, 1);
   }
 },
  seleciona(option){
    if(option.id_genero){
      this.pessoa.pessoa.id_genero = option.id_genero
    }

    if(option.id_estado_civil){
      this.pessoa.pessoa.id_estado_civil = option.id_estado_civil
    }

    if(option.id_escolaridade){
      this.pessoa.pessoa.id_escolaridade = option.id_escolaridade
    }

  }
},

watch:{
 'pessoa.pessoa.nome': function(val){
   if(val.length == ''){
     this.textoNome = true;
     this.btn.push('nome');
     this.textoNomeDescricao = 'Insira um nome';
   }else if(val.length > 255){
      this.textoNomeDescricao = 'O nome deve ter no maximo 255 caracteres';
      this.textoNome = true;
      this.btn.push('nome');
   }
   else{
     this.textoNome = false;
     this.removerValidacao('nome');
   }
 },
 'pessoa.pessoa.id_genero':{
    handler: function(val){
     if(val == null || val === 'vazio'){
       this.textoGenero = true;
       this.btn.push('genero')
     }else{
       this.textoGenero = false;
       this.removerValidacao('genero');
     }
   },
   immediate: true
 },
 'pessoa.pessoa.nascimento':{
   handler: function(val){
     if(val == null || val == ''){
       this.textoNascimento = true;
       this.btn.push('nascimento')
     }else{
       this.textoNascimento = false;
       this.removerValidacao('nascimento');
     }
   }
 },
 'pessoa.pessoa.id_escolaridade': {
   handler: function(val){
     if(val == null || val === 'vazio'){
       this.textoEscolaridade = true;
       this.btn.push('escolaridade')
     }else{
       this.textoEscolaridade = false;
       this.removerValidacao('escolaridade');
     }
   },
   immediate: true
 },
 'pessoa.pessoa.id_estado_civil':{
    handler: function(val){
     if(val == null || val === 'vazio'){
       this.textoEstadoCivil = true;
       this.btn.push('estado_civil')
     }else{
       this.textoEstadoCivil = false;
       this.removerValidacao('estado_civil');
     }
   },
   immediate: true
 },
 'pessoa.pessoa.renda_bruta':{
   handler: function(val){
     val = parseFloat(val);
     if(val>=0){
         this.rendaBruta = parseFloat(val);
     }
   },
   immediate: true
 },
},
}

</script>

<style scoped>
#modal-confirmar{
  overflow: auto;
}
.header-modal-cora{
  height: 122px;
  padding: 3%;
  background: linear-gradient(150deg, rgba(36,109,186,1) 0%, rgba(4,42,108,1) 60%, rgba(85,44,145,1) 100%);
  border-radius: 6px 6px 0 0;
  border-bottom: none;
}
.titulo-modal-cora{
  font-family: "Montserrat",serif;
  font-weight: 500;
  font-size: 24px;
  margin-top: 20px;
  margin-left: 15px;
  line-height: 29.26px;
  color: #fff;
}
.subtitulo-modal-cora{
  font-family: "Montserrat",serif;
  font-weight: 300;
  font-size: 1.1rem;
  color: #fff;
  margin-left: 15px;
}

.modal .modal-header .close {
  display: none;
}

.option-select{
  color: #a463f2;
  padding: 20px;
}

.select-cora{
  background-color:#FFFFFF;
}

.modal-body{
  padding: 0;
}

/* FORM */
.container-modal{
  padding: 3%;
  background-color: #e5eef7;
}
.modal-footer{
  background-color: #e5eef7;
}
.form-cora-input{
  padding: 1%;
  border-radius: 6px;
  gap: 10px;
  height: 35px;
  font-family: Montserrat,serif;
  font-weight: 300;
  font-size: 0.9rem;
  border: 0;
  outline: 0;
  width: 100%;
}


.montserrat {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}
.labelCora{
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 0.9rem;
  line-height: 17.07px;
  color: #002060;
}
.botao-modal-cora{
  width: 45%;
  max-width: 45%;
  min-height: 30px;
  background-color: #002060;
  color: #fff;
  border-radius: 20px;
  box-shadow: 0 4px 8px #0000001f;
  justify-content: center;
  align-items: center;
  font-family: Montserrat,serif;
  font-weight: 300;
  cursor: pointer;
  margin-top: 2%;
  border: none;
  font-size: 1.1rem;
}
.form-group{
  margin-bottom: .5rem ;
}
.botao-modal-cora-desativado{
 width: 45%;
 max-width: 45%;
 min-height: 30px;
 background-color: #00206180;
 color: #fff;
 border-radius: 20px;
 box-shadow: 0 4px 8px #0000001f;
 font-size: 1.1rem;
 border: none;
 justify-content: center;
 align-items: center;
 font-family: Montserrat,serif;
 font-weight: 300;
 cursor: default;
 margin-top: 2%;
}


@media (max-width: 800px) {
  #modal-confirmar{
    overflow: auto;
  }   
}

@media (max-width: 600px) {
  .titulo-modal-cora{
    font-size: 2rem;
  }
}

@media (max-width: 500px) {
  .titulo-modal-cor{
    margin-top: 0;
    margin-left: 0;
  }
  .subtitulo-modal-cora{
    margin-left: 0;
  }
}

@media (min-height: 500px) and (max-height: 661px) {
    #modal-confirmar{
        height: 100vh;
        overflow: auto;
    }

    .label {
      margin-bottom: 0.1257rem !important;
    }

    .form-group{
        margin-bottom: .1rem !important;
    }
    .form-cora-input{
        height: 21.5px !important;
        padding: 0.7%;
    }
    .container-modal{
      padding: 1.3% !important;
    }
    .header-modal-cora{
      padding: 2% !important;
      height: 110px !important;
    }
}

@media (min-height: 662px) and (max-height:800px) {
    #modal-confirmar{
        height: 100vh ;
    }
    .form-group{
        margin-bottom: .3rem;
    }

    .form-cora-input{
        height: 21.5px !important;
        padding: 0.7%;
    }
    .modal-footer{
      padding: 0px !important;
    }
    .container-modal{
      padding: 1.3% !important;
    }

    .header-modal-cora{
      padding: 2% !important;
      height: 110px !important;
    }

}

@media (max-width: 400px) {
  .form-group{
    margin-bottom: 0.1rem !important;
  }
  #modal-confirmar{
    margin-top: 5px !important;
  }
  .header-modal-cora{
    height: 90px !important;
  }
}


</style>
