<template>
  <div class="custom-select">
    <div class="select-header form-group" @click="toggleDropdown">
      <span v-if="selecao">{{ selecionadoOpcao == null ? `Escolha  ${tipo}` : selecionadoOpcao }}</span>
      <div class="arrow" :class="{ 'open': isOpen }"></div>
    </div>
    <div v-if="isOpen && !sortedOptions" :class="{'options': !isEscolaridade, 'options-escolaridade': isEscolaridade}">
      <div
        v-for="(option, index) in options"
        :key="index"
        :class="{'option': !isEscolaridade,'selected': verificarOpcaoSelecionada(option)}"
        @click="selectOption(option)"
      >
        <span>{{ option.descricao}}</span>
      </div>
    </div>
    <div v-if="isOpen && sortedOptions" class='options-escolaridade'>
      <div
        v-for="(option, index) in sortedOptions"
        :key="index"
        :class="{'option': !isEscolaridade,'option-escolaridade':isEscolaridade,'selected': verificarOpcaoSelecionada(option)}"
        @click="selectOption(option)"
      >
        <span>{{ option.descricao}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectPersonalizado',
  props: {
    pessoa:{
      type: Object,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    tipo:{
      type: String,
      required: true,
    }
  },
  computed: {
    sortedOptions() {
      const nivelEscolaridade = {
        "Não alfabetizado": 0,
        "Ensino fundamental incompleto": 1,
        "Ensino fundamental completo": 2,
        "Ensino médio incompleto": 3,
        "Ensino médio completo": 4,
        "Curso técnico incompleto": 5,
        "Curso técnico completo": 6,
        "Superior incompleto": 7,
        "Superior completo": 8,
        "Pós-graduação incompleta": 9,
        "Pós-graduação completa": 10,
        "Mestrado": 11,
        "Doutorado": 12,
        "Não informado": 13,
      };

      return this.options.slice().sort((a, b) => {
        return nivelEscolaridade[a.descricao] - nivelEscolaridade[b.descricao];
      });
    },
    hasEscolaridade() {
      return this.options[0]?.id_escolaridade !== undefined;
    }
  },
  data() {
    return {
      isOpen: false,
      selecao: false,
      isEscolaridade:false,
      selectedOption: null,
      selecionadoOpcao: null,
    };
  },

  mounted() {
    this.selectedOption = this.$props.options;
    this.verificaDescricao();
    this.verificaIsEscolaridade();
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    selectOption(option) {

      this.selecionadoOpcao = option.descricao
      this.isOpen = false;
      this.$emit('seleciona', option);
    },
    verificaDescricao() {
      this.options.forEach(element => {
        if (element.id_genero === this.pessoa.id_genero ||
            element.id_escolaridade === this.pessoa.id_escolaridade ||
            element.id_estado_civil === this.pessoa.id_estado_civil) {
          this.selecionadoOpcao = element.descricao;
        }
      });
      this.selecao = true;
    },
    verificarOpcaoSelecionada(option) {
      return this.pessoa.id_genero === option.id_genero ||
             this.pessoa.id_escolaridade === option.id_escolaridade ||
             this.pessoa.id_estado_civil === option.id_estado_civil;
    },

    verificaIsEscolaridade(){
      if(this.$props.options[0]?.id_escolaridade){
        this.isEscolaridade = true
      }
    }
  },
}
</script>

<style scoped>
.custom-select {
  position: relative;
  background: none;
  width: 100%;
  border: none;
  padding: 9px;
  border-radius: 4px;
  background-color: #FFFFFF;
  font-family: "Monsterrat",sans-serif;
}

.select-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.arrow {
  width: 9px;
  height: 5.5px;
  background-image: url('../../../../assets/images/cora/icon-arrow-cora.svg');
  transition: transform 0.2s ease;
}

.arrow.open {
  transform: rotate(180deg);
}

.options {
  position: absolute;
  width: 90%;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  z-index: 1000;
  margin-top: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.options-escolaridade{
  position: absolute;
  width: 90%;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  z-index: 1001;
  bottom: 45px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.option {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.option-escolaridade {
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.option-escolaridade.selected {
  background-color: #F9EEFF;
  color: #7030A0;
}

.option.selected {
  background-color: #F9EEFF;
  color: #7030A0;
  font-weight: bold;
}
.option-escolaridade:hover {
  background-color: #f0f0f0;
}
.option:hover {
  background-color: #f0f0f0;
}

/* Media queries */
@media (max-height:800px) {
  .custom-select {
    padding: 4px;
    height: 24px;
  }
  .option-escolaridade{
    padding: 2px 10px;
  }
}
</style>
